<template>
  <div style="">
    <el-row style="padding-bottom: 15px">
      <el-button type="primary" @click="refresh">{{$t("deviceGateway.refresh")}}</el-button>
      <el-button type="primary" @click="sendA0">{{$t("deviceGateway.sendA0")}}</el-button>
    </el-row>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="">
      <el-table-column
        prop="type"
        :label="$t('deviceGateway.type')"
        sortable
        width="180"
        align="center">
      </el-table-column>
      <el-table-column
        prop="buffer"
        :label="$t('deviceGateway.packet')">
      </el-table-column>
      <el-table-column
        prop="revTime"
        :label="$t('deviceGateway.receivingTime')"
        sortable
        width="210"
        align="center">
      </el-table-column>
    </el-table>
    <div style="padding-bottom: 10px;padding-top: 10px">
      <el-divider content-position="left">
        {{$t("deviceGateway.repair")}}
      </el-divider>
    </div>
    <el-form v-loading="a0loading" :inline="true">
      <el-form-item :label="$t('deviceGateway.runNumber')" :label-width="$t('deviceGateway.labelWidth1')">
        <el-input
          v-model="runtimes"
          :placeholder="$t('deviceGateway.runNumber')"
          oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item :label="$t('deviceGateway.runTime')" :label-width="$t('deviceGateway.labelWidth1')">
        <el-input
          v-model="runtime"
          :placeholder="$t('deviceGateway.runTime')"
          oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="setSpecialStatus">{{$t("deviceGateway.setting")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="readSpecialStatus">{{$t("deviceGateway.read")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="clearSpecialStatus">{{$t("deviceGateway.clear")}}</el-button>
      </el-form-item>
    </el-form>
    <div style="padding-bottom: 10px;padding-top: 10px">
      <el-divider content-position="left">
        {{$t("deviceGateway.lastTime")}}
      </el-divider>
    </div>
    <el-form v-loading="upRuntimesloading" :inline="true">
      <el-form-item :label="$t('deviceGateway.runNumber')" :label-width="$t('deviceGateway.labelWidth1')">
        <el-input
          v-model="upRuntimes"
          :placeholder="$t('deviceGateway.runNumber')"
          oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="readA0Runtimes">{{$t("deviceGateway.setting")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="clearA0Runtimes">{{$t("deviceGateway.clear")}}</el-button>
      </el-form-item>
    </el-form>
    <div v-if="integrationKey ==='shenyang'" style="padding-bottom: 10px;padding-top: 10px">
      <el-divider content-position="left">
        {{$t("deviceGateway.dockingOffline")}}
      </el-divider>
    </div>
    <el-form v-if="integrationKey ==='shenyang'" v-loading="cutloading" :inline="true">
      <el-form-item :label="$t('deviceGateway.OfflinePower')" :label-width="$t('deviceGateway.labelWidth1')">
        <el-switch
          v-model="offToCut"
          :active-text="$t('elevator.yes')"
          :inactive-text="$t('elevator.no')"></el-switch>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="setDeviceOffToCut">{{$t("deviceGateway.setting")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="readDeviceOffToCut">{{$t("deviceGateway.read")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="clearDeviceOffToCut">{{$t("deviceGateway.clear")}}</el-button>
      </el-form-item>
    </el-form>

    <!-- v-if="integrationKey ==='liaoning'"-->
    <div style="padding-bottom: 10px;padding-top: 10px">
      <el-divider content-position="left">
        {{$t("deviceGateway.A0Running")}}
      </el-divider>
    </div>
    <el-form v-loading="liaoningloading" :inline="true">
      <div>
        <el-form-item :label="$t('deviceGateway.initialRunNumber')" :label-width="$t('deviceGateway.labelWidth1')">
          <el-input
            v-model="liaoning.iniRuntimes"
            :placeholder="$t('deviceGateway.initialRunNumber')"
            oninput="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.initialRunTime')" :label-width="$t('deviceGateway.labelWidth1')">
          <el-input
            v-model="liaoning.iniRuntime"
            disabled
            :placeholder="$t('deviceGateway.initialRunTime')"
            oninput="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.currentRunNumber')" :label-width="$t('deviceGateway.labelWidth1')">
          <el-input
            v-model="liaoning.runtimes"
            disabled
            :placeholder="$t('deviceGateway.currentRunNumber')"
            oninput="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('deviceGateway.currentRunningTime')" :label-width="$t('deviceGateway.labelWidth1')">
          <el-input
            v-model="liaoning.runtime"
            disabled
            :placeholder="$t('deviceGateway.currentRunningTime')"
            oninput="value=value.replace(/[^\d]/g,'')"></el-input>
        </el-form-item>
      </div>
      <div>
        <el-form-item label-width="80px">
          <el-button type="primary" @click="setLiaoningA0Runtime">{{$t("deviceGateway.setting")}}</el-button>
        </el-form-item>
        <el-form-item label-width="80px">
          <el-button type="primary" @click="readLiaoningA0Runtime">{{$t("deviceGateway.read")}}</el-button>
        </el-form-item>
        <el-form-item label-width="80px">
          <el-button type="primary" @click="clearLiaoningA0Runtime">{{$t("deviceGateway.clear")}}</el-button>
        </el-form-item>
      </div>
    </el-form>

    <div style="padding-bottom: 10px;padding-top: 10px">
      <el-divider content-position="left">
        {{$t("deviceGateway.polarP")}}
      </el-divider>
    </div>
    <el-form v-loading="logarithmloading" :inline="true">
      <el-form-item :label="$t('deviceGateway.motorType')" :label-width="$t('deviceGateway.labelWidth1')">
        <el-switch
          v-model="motor.motorTypeMark"
          :active-text="$t('deviceGateway.synchronous')"
          :inactive-text="$t('deviceGateway.asynchronous')"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('deviceGateway.polarP')" :label-width="$t('deviceGateway.labelWidth1')">
        <el-input
          v-model="motor.logarithm"
          :placeholder="$t('deviceGateway.polarP')"
          oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="setLogarithm">{{$t("deviceGateway.setting")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="readLogarithm">{{$t("deviceGateway.read")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="clearLogarithm">{{$t("deviceGateway.clear")}}</el-button>
      </el-form-item>
    </el-form>

    <div style="padding-bottom: 10px;padding-top: 10px">
      <el-divider content-position="left">
        {{$t("deviceGateway.MkEvent")}}
      </el-divider>
    </div>
    <el-form :inline="true">
      <el-form-item label-width="80px">
        <el-button type="primary" @click="sendEvent(9)">{{$t("deviceGateway.automatic")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="sendEvent(10)">{{$t("deviceGateway.overhaul")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="sendEvent(7)">{{$t("deviceGateway.normal")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="sendEvent(30)">{{$t("deviceGateway.AlarmBell")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="sendEvent(1)">{{$t("deviceGateway.PowerGateway")}}</el-button>
      </el-form-item>
      <el-form-item label-width="80px">
        <el-button type="primary" @click="sendEvent(2)">{{$t("deviceGateway.GatewayPowered")}}</el-button>
      </el-form-item>
    </el-form>

    <div style="padding-bottom: 10px;padding-top: 10px">
      <el-divider content-position="left">
        {{$t("deviceGateway.orderMkEvent")}}
      </el-divider>
    </div>

    <el-form :inline="true">
      <el-form-item label="" :label-width="$t('deviceGateway.labelWidth1')">
        <el-radio-group v-model="workOrder.personShutting">
          <el-radio :label="0">{{$t("deviceGateway.NotTrappingPeople")}}</el-radio>
          <el-radio :label="1">{{$t("deviceGateway.TrappingPeople")}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('deviceGateway.faultCode')" :label-width="$t('deviceGateway.labelWidth1')">
        <el-input
          v-model="workOrder.faultCode"
          :placeholder="$t('deviceGateway.faultCode')"
          oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item :label-width="$t('deviceGateway.labelWidth1')">
        <el-button type="primary" @click="sendWorkOrder()">{{$t("deviceGateway.sendOrder")}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

  export default {
    props: {
      dtuCode: {
        type: String,
        default: "",
      },
      nodeCode: {
        type: String,
        default: "",
      },
      integrationKey: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        loading: false,
        a0loading: false,
        upRuntimesloading: false,
        cutloading: false,
        offToCut: false,
        logarithmloading: false,
        upRuntimes: "",
        runtime: "",
        runtimes: "",
        tableData: [],
        liaoningloading: false,
        liaoning: {
          iniRuntimes: "",
          iniRuntime: "",
          runtimes: "",
          runtime: "",
        },
        motor: {
          logarithm: "",
          motorTypeMark: false,
          motorType: this.$t("deviceGateway.asynchronous"),
        },
        workOrder: {
          faultCode: "",
          personShutting: "",
        },
      };
    },
    methods: {
      setSpecialStatus() {
        let params = {
          dtuCode: this.dtuCode,
          nodeCode: this.nodeCode,
          runtime: this.runtime,
          runtimes: this.runtimes,
        };
        this.$confirm(this.$t("deviceGateway.confirmSettings"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.a0loading = true;
          this.$http.post("deviceGateway/setSpecialStatus", params).then(({data}) => {
            this.$message.success(this.$t("common.tip.settingSuccess"));
            this.a0loading = false;
          }).catch((error) => {
            this.a0loading = false;
          });
        });
      },
      readSpecialStatus() {
        this.a0loading = true;
        this.$http.get("deviceGateway/readSpecialStatus/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
          this.runtime = data.runtime;
          this.runtimes = data.runtimes;
          this.a0loading = false;
        }).catch((error) => {
          this.a0loading = false;
        });
      },
      clearSpecialStatus() {
        this.$confirm(this.$t("deviceGateway.confirmClear"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.a0loading = true;
          this.$http.post("deviceGateway/clearSpecialStatus/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
            this.runtime = "";
            this.runtimes = "";
            this.$message.success(this.$t("deviceGateway.clearSuccess"));
            this.a0loading = false;
          }).catch((error) => {
            this.a0loading = false;
          });
        });
      },
      readA0Runtimes() {
        this.upRuntimesloading = true;
        this.$http.get("deviceGateway/readA0Runtimes/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
          this.upRuntimes = data;
          this.upRuntimesloading = false;
        }).catch((error) => {
          this.upRuntimesloading = false;
        });
      },
      clearA0Runtimes() {
        this.$confirm(this.$t("deviceGateway.confirmClear"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.upRuntimesloading = true;
          this.$http.post("deviceGateway/clearA0Runtimes/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
            this.upRuntimes = "";
            this.$message.success(this.$t("deviceGateway.clearSuccess"));
            this.upRuntimesloading = false;
          }).catch((error) => {
            this.upRuntimesloading = false;
          });
        });
      },
      setLiaoningA0Runtime() {
        this.$confirm(this.$t("deviceGateway.confirmSettings"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.liaoningloading = true;
          this.$http.post("deviceGateway/setLiaoningA0Runtime/" + this.dtuCode + "/" + this.nodeCode + "/" + this.liaoning.iniRuntimes).then(({data}) => {
            this.$message.success(this.$t("common.tip.settingSuccess"));
            this.liaoningloading = false;
            this.readLiaoningA0Runtime();
          }).catch((error) => {
            this.liaoningloading = false;
          });
        });
      },
      readLiaoningA0Runtime() {
        this.liaoningloading = true;
        this.$http.get("deviceGateway/readLiaoningA0Runtime/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
          this.liaoningloading = false;
          this.liaoning.iniRuntimes = data["iniRuntimes"];
          this.liaoning.iniRuntime = data["iniRuntime"];
          this.liaoning.runtimes = data["runtimes"];
          this.liaoning.runtime = data["runtime"];
        }).catch((error) => {
          this.liaoningloading = false;
        });
      },
      clearLiaoningA0Runtime() {
        this.$confirm(this.$t("deviceGateway.confirmClear"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.liaoningloading = true;
          this.$http.post("deviceGateway/clearLiaoningA0Runtime/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
            this.liaoning = {
              iniRuntimes: "",
              iniRuntime: "",
              runtimes: "",
              runtime: "",
            };
            this.$message.success(this.$t("deviceGateway.clearSuccess"));
            this.liaoningloading = false;
          }).catch((error) => {
            this.liaoningloading = false;
          });
        });
      },
      setLogarithm() {
        if (this.motor.motorTypeMark) {
          this.motor.motorType = this.$t("deviceGateway.synchronous");
        } else {
          this.motor.motorType = this.$t("deviceGateway.asynchronous");
        }
        this.$confirm(this.$t("deviceGateway.confirmSettings"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.logarithmloading = true;
          this.$http.post("deviceGateway/setLogarithm/" + this.dtuCode + "/" + this.nodeCode, this.motor).then(({data}) => {
            this.$message.success(this.$t("common.tip.settingSuccess"));
            this.logarithmloading = false;
          }).catch((error) => {
            this.logarithmloading = false;
          });
        });
      },
      readLogarithm() {
        this.logarithmloading = true;
        this.$http.get("deviceGateway/readLogarithm/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
          this.motor.logarithm = data["logarithm"];
          this.motor.motorType = data["motorType"] == this.$t("deviceGateway.synchronous") ? this.$t("deviceGateway.synchronous") : this.$t("deviceGateway.asynchronous");

          if (this.motor.motorType === this.$t("deviceGateway.synchronous")) {
            this.motor.motorTypeMark = true;
          } else {
            this.motor.motorTypeMark = false;
          }

          console.log(this.motor);
          this.logarithmloading = false;
        }).catch((error) => {
          this.logarithmloading = false;
        });
      },
      clearLogarithm() {
        this.$confirm(this.$t("deviceGateway.confirmClear"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.logarithmloading = true;
          this.$http.post("deviceGateway/clearLogarithm/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
            this.upRuntimes = "";
            this.$message.success(this.$t("deviceGateway.clearSuccess"));
            this.logarithmloading = false;
          }).catch((error) => {
            this.logarithmloading = false;
          });
        });
      },
      setDeviceOffToCut() {
        let params = {
          dtuCode: this.dtuCode,
          nodeCode: this.nodeCode,
          offToCut: this.offToCut,
        };
        this.$confirm(this.$t("deviceGateway.confirmSettings"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.cutloading = true;
          this.$http.post("deviceGateway/setDeviceOffToCut", params).then(({data}) => {
            this.$message.success(this.$t("common.tip.settingSuccess"));
            this.cutloading = false;
          }).catch((error) => {
            this.cutloading = false;
          });
        });
      },
      readDeviceOffToCut() {
        this.cutloading = true;
        this.$http.get("deviceGateway/readDeviceOffToCut/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
          this.offToCut = data.offToCut;
          this.cutloading = false;
        }).catch((error) => {
          this.cutloading = false;
        });
      },
      clearDeviceOffToCut() {
        this.$confirm(this.$t("deviceGateway.confirmClear"), this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.cutloading = true;
          this.$http.post("deviceGateway/clearDeviceOffToCut/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
            this.offToCut = false;
            this.cutloading = false;
            this.$message.success(this.$t("deviceGateway.clearSuccess"));
          }).catch((error) => {
            this.cutloading = false;
          });
        });
      },
      open(dtuCode, nodeCode) {

      },
      refresh() {
        this.loading = true;
        this.$http.get("deviceGateway/nodeInfo/" + this.dtuCode + "/" + this.nodeCode).then(({data}) => {
          this.tableData = data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
      },
      sendA0() {
        let params = {
          "dtuCode": this.dtuCode,
          "nodeCode": this.nodeCode,
        };
        this.$api.getData("manualSchedule/sendA0", params).then(res => {
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch((err) => {
          this.$message.error(err);
        });
      },
      sendEvent(code) {
        let params = {
          "dtuCode": this.dtuCode,
          "nodeCode": this.nodeCode,
          "eventCode": code,
        };
        this.$http.post("deviceGateway/sendEvent", params).then(({data}) => {
          this.$message.success(this.$t("deviceGateway.eventSendSuccess"));
        }).catch((error) => {
        });
      },
      sendWorkOrder() {
        let params = {
          "dtuCode": this.dtuCode,
          "nodeCode": this.nodeCode,
          "faultCode": this.workOrder.faultCode,
          "personShutting": this.workOrder.personShutting,
        };
        this.$http.post("deviceGateway/sendWorkOrder", params).then(({data}) => {
          this.$message.success(this.$t("deviceGateway.orderSendSuccess"));
        }).catch((error) => {
        });
      },

    },
  };
</script>

<style lang="scss" scoped>

</style>
