import { render, staticRenderFns } from "./DevicePatch.vue?vue&type=template&id=1de7f215&scoped=true"
import script from "./DevicePatch.vue?vue&type=script&lang=js"
export * from "./DevicePatch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1de7f215",
  null
  
)

export default component.exports